.footer{
  background: #6D6F7A;
  &-row{
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include to(md){
      padding: 20px 0;
    }
  }

  position: relative;
}
.footer-menu{
  ul{
    display: flex;
    padding: 0;
    list-style: none;
  }
  li{
    &:not(:last-child){
      margin-right: 25px;
    }
  }
  a{
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
}