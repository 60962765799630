.wrapper_td {
  height: 320px;
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #cccccc;
  transition: $trans;
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover {
    border: 1px solid #a186be;
  }
}

#table_order td {
  border: 1px solid #eeeeee;
  background: #f7f7f7;
  text-align: center!important;
}

.cmlt_1 {
  width: 200px;
  height: 153px;
  margin: 16px auto 0px auto;
}

a.details:hover {
  text-decoration: none !important;
}

.cmlt_3 {
  clear: both;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px 5px 10px;
  margin: 3px auto 0px auto !important;
  text-align: center;
  font: 13px/100% Verdana !important;
  color: #767676 !important;
  text-transform: none !important;
  border-bottom: 1px solid #c9c9c9;
  @include to(md){
    border-bottom: 0 !important;
  }
  position: relative;
  flex-grow: 1;
}

span.spin{
  display:block;
  margin-left: 10px;
  float:left;
  @include to(lg){
    display: none;
  }
}

.wrapp {
  border: 1px solid #e6e8d3;
  margin-bottom: 30px;
  &__img{
   display: block;
    width: 100%;
    height: 205px;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__text{
    display: block;
    width: 100%;
    padding: 7px 10px 7px 15px;
    background: #ececec;
    font: 11px/16px Verdana;
    text-transform: uppercase;
    text-decoration: none;
   a{
     color: #424242;
   }
  }
}
span.text_brands {
  display: none;
}
.wrapp.hover {
  border: 1px solid #9e82b2;
}

.wrapp p.bold.hover a {
  background: #9e82b2;
  color: #fff !important;
}
.firstnavi.parent > a{
  position: relative;
  &:before,&:after{
    content: '';
    width: 12px;
    position: absolute;
    background: #9E9FA7;
    height: 1px;
    top: 16px;

    right: 12px;

    transition: $trans;
  }
  &:after{
    transform: rotate(90deg);
  }
  &.active{
    &:after{
      transform: rotate(0deg);
    }
  }
}

.banner{
  clear:both;
  width: 100%;
  padding-top:17px;
  @include to(lg){
    display: none;
  }
}

.filename a{
  display:block;
  float:left;
  margin: 0 10px 10px 0px;
  padding:5px;
  background:none!important;
  border:2px solid #ececec;
  height: 132px;
  width: 132px;
}
.filename img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.catalog_menu--page{
  display: block;
 @include to(md){
   display: none;
 }
  &.open{
    display: block;
  }
  &-close{
    display: none;
    @include to(md){
      display: block;
      padding: 15px 12px 15px 0;
      text-align: right;
    }
  }
  @include to(md){
    position: fixed;
    background: #fff;
    width: 266px;
    left: 0;
    top: 0;
    z-index: 9999;
    height: 100%;
    overflow-x: auto;
  }
}
#content .txt-for h3 {
  @include to(md){
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #333333;
  }
}
.goods_photo{
  @include to(md){
    display: none;
  }
}
.filename{
@include to(md){
  display: none;
}
}


.omlt , .descr , .crit , table#characteristics {
  @include to(md){
    width: 100%;
  }
}
#characteristics td {

  @include to(md){
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #6D6F7A;
  }

}
.product-slider{
  display: none;
  @include to(md){
    display: block;
  }
  img{
    width: 100%;
    height: 261.34px;
    object-fit: contain;
  }
}
.omlt_1_1{

  text-align:left;
  margin:10px 0px 0px 0px;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #333333;
  b{
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }
}
.goods_description {
  margin:18px 0px 0px 0px;
  width: 100%;
}
.omlt{
  max-width: 530px;
  float:right;
  margin:30px 0px 0px 0px;
}

h1.h1_detail_description {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;

}

.descr{
  max-width: 530px;
  width: 100%;
  float:right;}


.crit{
  float:left;
  padding:0px!important;
  margin:0px;
  text-align:left;}
.detail_wrap_spinner{
  display: flex;
  align-items: center;
  span{
    font-size: 14px;
    line-height: 18px;
    @include to(md){
      font-size: 12px;
      line-height: 18px;
    }
    color: #333333;
  }
}

.omlt_2 {
  margin: 18px 0px 30px 0px;
  text-align: left;
  @include to(md){
    margin: 18px 0px 24px 0px;
    font-size: 14px;
    line-height: 20px;
  }
}
.prise-row{
  display: flex;
  justify-content: space-between;
  max-width: 375px;
  margin-bottom: 35px;
  .with_spinner input, .with_spinner2 input {
    width: 30px!important;
  }
}
.button_buy.detail_description {
  float: left;
  height: 44px;
  width: 100%;
  max-width: 200px;
  padding: 0 10px;
  background: #8D65A7;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: $trans;
  &:hover{
    color: #FFFFFF;
    background: lighten(#8D65A7 , 10%);
  }
}
.crit {
  width: 100%;
}
.crit table{
  width: 100%;
}
button.close2 {
  right: 7px !important;
  left: auto !important;
  top: 5px !important;
  border: 0;
  position: absolute;
  background: transparent;
}
.desc_caption {
  color: #333333;
}
.ch{
  font-size:11px;
  margin: 0 3px;
  @include to(md){
    font-size:10px;
  }
}
.breadcramps {
  margin-bottom: 34px;
  .last{
    color: #000000;
  }
}

.product-slider{
  .swiper-pagination-bullet {
    background: #C9C9C9;
  }
}
div#first tr {
  @include to(md){
    display: flex;
    flex-direction: column;
  }
}
.button-bay{
  @include to(md){
    display: flex;
    width: 100% !important;
    height: 44px !important;
    margin: 0 !important;
    justify-content: center;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 2px;
    text-transform: uppercase !important;
  }
}
.pc-row{
  @include to(md){
    display: none;
  }
}
.row-mob-flex{
  @include to(md){
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid;
    padding: 16px 12px 0;
    background: #F8F8F9;
  }

  .td-grid{
    @include to(md) {
      display: grid;
      grid-template-columns: 100px 1fr;
    }
  }
  .row-cine{
    @include to(md) {
      display: flex;
      width: 100% !important;
      float: right;
    }
  }
}
#content table,#content tbody{
  @include to(md){
    width: 100%;
    display: block;
  }
}

#table_order td {
  @include to(md) {
    border: 1px solid #eeeeee;
    background: #f7f7f7;
    text-align: center !important;
  }
  .img{
    @include to(md) {
      border: 1px solid #D6D6D6;
      width: 80px;
      height: 80px;
    }
  }
  span, a{
    @include to(md){
      text-align: left;
    }
  }
  @include to(md){
    border: 0;
    padding: 0;
    margin-bottom: 16px;
  }
}
.mob-title{
  display: none;
  @include to(md){
    display: block;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #6D6F7A;
  }
  & + span ,  & + a {
    @include to(md) {
      font-size: 12px;
      line-height: 18px;
      color: #333333;
    }

  }
}
.ddel {
  @include to(md){
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.countch {
  @include to(md){
    width: 38px;
    height: 26px;
  }
}
.bottom-td{
  @include to(md) {
    margin-bottom: 0 !important;
  }
}
.bottom-bay{
  @include to(md) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 26px;
    margin-bottom: 30px;
  }
  td{
    @include to(md) {
    background: transparent !important;
    }
  }
  &__left{
    @include to(md) {
      font-size: 16px !important;
      color: #000000 !important;
      margin-bottom: 0 !important;
    }
  }
  &__right{
    @include to(md) {
      margin-bottom: 0 !important;
    }
    #clearbask{
      @include to(md) {
        font-size: 12px;
        text-decoration: underline !important;
        color: #8d65a7 !important;
      }
    }
  }
}
.for_oform{
  @include to(md){
    display: none;
  }
}
#first {
  font-size: 12px;
  line-height: 18px;
  color: #6D6F7A;
}
#first h2 {
  margin-bottom: 20px;
}
.sort{
  @include to(md){
    display: none;
  }
}
.bay_catalog{
  display: none;
  border: none;
  background: transparent;
  font-size: 14px;
  line-height: 24px;
  color: #6D6F7A;
  @include to(md){
    display: block;
    margin-bottom: 24px;
  }
}
a.second:not([href]){
  position: relative;
  &:before,&:after{
    content: '';
    width: 12px;
    position: absolute;
    background: #9E9FA7;
    height: 1px;
    top: 16px;
    right: 12px;
    transition: $trans;
  }
  &:after{
    transform: rotate(90deg);
  }
  &.active{
    &:after{
      transform: rotate(0deg);
    }
  }
}