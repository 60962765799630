.text_main h1{
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 25px;
  @include to(md){
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.06em;
  }
}
.text_main{
  background: #F8F8F9;
  padding: 80px 0;
  @include to(md){
    padding: 40px 0 70px;
  }
}
.text_main.text_main-white{
  background: #ffff;
}
.title{
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 48px;
}
.text {
  line-height: 18px;
  font-size: 12px;
  color: #333333;
 p{
   margin-bottom: 15px;
   margin-left: 0 !important;
 }
}
.txt-for {
  font-size: 12px;
  line-height: 18px;
  color: #333;
}


.text{
  ul{
    padding-left: 30px !important;
    margin-bottom: 15px !important;
  }
}
h1.h1_detail_description {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
  margin-bottom: 16px;
}
.TopicLine600{
  img{
    @include to(md){
      width: 100vw;
      object-fit: contain;
      display: block;
      height: 240px;
      background: #ccc;
      margin-bottom: 16px;
    }
  }
}
.text ul {

  li{
    @include to(md){
      margin-bottom: 10px !important;
    }
  }
}
p {
  text-indent: 0 !important;
}
.digits {
  ul{
    padding-left: 0 !important;
  }
}
a {
  font-size: 12px;
  line-height: 20px;
  color: #8D65A7;
  &:hover{
    color: #8D65A7;
  }
}