@charset "UTF-8";
@import url(/node_modules/bootstrap/dist/css/bootstrap-reboot.min.css);
@import url(/node_modules/bootstrap/dist/css/bootstrap-grid.min.css);
@import url(/node_modules/swiper/swiper-bundle.min.css);
@font-face {
  font-family: "Verdana";
  font-weight: 400;
  font-style: normal;
  src: url("../font/Verdana/Verdana.eot");
  src: url("../font/Verdana/Verdana.eot?#iefix") format("embedded-opentype"), url("../font/Verdana/Verdana.woff") format("woff"), url("../font/Verdana/Verdana.ttf") format("truetype");
}

@font-face {
  font-family: "Verdana";
  font-weight: 600;
  font-style: normal;
  src: url("../font/Verdana/Verdana-Bold.eot");
  src: url("../font/Verdana/Verdana-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Verdana/Verdana-Bold.woff") format("woff"), url("../font/Verdana/Verdana-Bold.ttf") format("truetype");
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.text_main h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .text_main h1 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.06em;
  }
}

.text_main {
  background: #F8F8F9;
  padding: 80px 0;
}

@media (max-width: 767px) {
  .text_main {
    padding: 40px 0 70px;
  }
}

.text_main.text_main-white {
  background: #ffff;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 48px;
}

.text {
  line-height: 18px;
  font-size: 12px;
  color: #333333;
}

.text p {
  margin-bottom: 15px;
  margin-left: 0 !important;
}

.txt-for {
  font-size: 12px;
  line-height: 18px;
  color: #333;
}

.text ul {
  padding-left: 30px !important;
  margin-bottom: 15px !important;
}

h1.h1_detail_description {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .TopicLine600 img {
    width: 100vw;
    object-fit: contain;
    display: block;
    height: 240px;
    background: #ccc;
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .text ul li {
    margin-bottom: 10px !important;
  }
}

p {
  text-indent: 0 !important;
}

.digits ul {
  padding-left: 0 !important;
}

a {
  font-size: 12px;
  line-height: 20px;
  color: #8D65A7;
}

a:hover {
  color: #8D65A7;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  list-style: none;
}

a[class] {
  text-decoration: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button {
  outline: none;
}

html,
body {
  min-height: 100%;
}

html,
body {
  height: 100%;
  font-family: "Verdana", sans-serif;
  scroll-behavior: smooth;
}

.container-fluid {
  max-width: 1175px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
}

.wrapper.active {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

.header {
  position: sticky;
  top: 0em;
  background: #fff;
  z-index: 999;
}

.header-top {
  display: flex;
  background: #FFFFFF;
}

.header-top__row {
  display: flex;
  padding: 14px 0;
  align-items: center;
}

@media (max-width: 991px) {
  .header-top__local {
    display: none;
  }
}

.header-bottom {
  background: #F8F8F9;
  position: relative;
}

@media (max-width: 991px) {
  .header-bottom {
    position: static;
  }
}

.header-bottom__row {
  align-items: center;
  display: flex;
  height: 56px;
}

@media (max-width: 991px) {
  .header-bottom__row {
    height: 0;
  }
}

.header-bottom__local {
  display: none;
}

@media (max-width: 991px) {
  .header-bottom__local {
    display: block;
  }
}

@media (max-width: 991px) {
  #ofbtn {
    display: none;
  }
}

.logo {
  width: 206px;
  margin-right: auto;
  height: 52px;
  display: block;
}

@media (max-width: 991px) {
  .logo {
    width: 126.77px;
    margin: 0 auto;
  }
}

.logo.logo--ru {
  background: url(../images/general/logo-ru.svg) no-repeat center;
  background-size: contain;
}

.logo.logo--ua {
  background: url(../images/general/logo-ua.svg) no-repeat center;
  background-size: contain;
}

.logo.logo--en {
  background: url(../images/general/logo-en.svg) no-repeat center;
  background-size: contain;
}

.search2 {
  border-radius: 20px;
  overflow: hidden;
  height: 40px;
  position: relative;
  background: #F8F8F9;
}

@media (max-width: 991px) {
  .search2 {
    display: none;
  }
}

.search2 input {
  position: absolute;
  width: 100%;
  border: 0;
  font-size: 12px;
  line-height: 22px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #909090;
  left: 0;
  background: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  padding-left: 50px;
}

.search2 #sf_button {
  position: absolute;
  top: 7px;
  left: 13px;
}

.search {
  width: 100%;
  max-width: 433px;
  height: 33px;
  margin: 0px 0px 0px 0px;
}

@media (max-width: 991px) {
  .search {
    display: none;
  }
}

.search .input {
  margin: 0px;
  position: relative;
}

.lg {
  display: flex;
  padding-left: 0;
  margin-left: 30px;
}

@media (max-width: 991px) {
  .lg {
    margin-left: 0;
    margin-bottom: 38px;
  }
}

.lg a {
  font-size: 12px;
  line-height: 18px;
  color: #6D6F7A;
}

.lg a:hover {
  color: #8D65A7;
}

body.ru .lg .ru a {
  color: #8D65A7 !important;
}

body.ua .lg .uk a {
  color: #8D65A7;
}

body.en .lg .eng a {
  color: #8D65A7;
}

.razd {
  margin: 0 4px;
  font-size: 12px;
  line-height: 27px;
  color: #6D6F7A;
}

#korzinfo {
  display: flex;
}

@media (max-width: 991px) {
  #korzinfo {
    position: absolute;
    right: 0;
    top: 28px;
  }
}

#top_menu {
  margin-right: auto;
}

@media (max-width: 991px) {
  #top_menu {
    display: none;
  }
}

.bay-block {
  display: flex;
  align-items: center;
  margin-right: 18px;
}

.bay-block__text {
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

@media (max-width: 991px) {
  .bay-block__text {
    display: none;
  }
}

.bay-icon {
  position: relative;
  margin-right: 12px;
  display: block;
}

.bay-icon__cine {
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #8D65A7;
  color: #FFFFFF;
  position: absolute;
  border-radius: 50%;
  padding: 1px 5px;
  right: -5px;
  top: -5px;
}

.header-catalog {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 9;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(92, 92, 92, 0.24);
  padding-top: 30px;
  padding-bottom: 15px;
  max-height: 520px;
  overflow-x: auto;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .header-catalog {
    position: fixed;
    background: #FFFFFF;
    max-height: 100%;
    padding-top: 16px;
    padding-left: 14px;
    padding-right: 14px;
    top: 0;
    height: 100%;
    max-width: 266px;
    left: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  }
}

.header-catalog.open {
  opacity: 1;
  pointer-events: auto;
}

.header-catalog__close {
  display: none;
  right: 15px;
  top: 15px;
  position: absolute;
}

@media (max-width: 991px) {
  .header-catalog__close {
    display: block;
  }
}

.header-catalog .firstnavi {
  margin-bottom: 16px;
}

.header-catalog .firstnavi ul {
  margin-top: 5px;
}

.header-catalog .v1 > li {
  margin-bottom: 16px;
}

.header-catalog ul {
  list-style: none;
  padding: 0;
}

.header-catalog .firstnavi > a {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  text-transform: uppercase;
}

.header-catalog .second {
  font-size: 12px;
  line-height: 18px;
  color: #5C5C5C;
}

@media (max-width: 991px) {
  .header-catalog .second {
    text-transform: none;
  }
}

.header-catalog a {
  transition: 0.3s ease-in-out;
}

.header-catalog a:hover {
  color: #8D65A7;
}

.header-catalog a:hover:before, .header-catalog a:hover:after {
  background: #8D65A7 !important;
}

.header-catalog .parent > a {
  position: relative;
  display: block;
  padding-right: 26px;
}

.header-catalog .parent > a:before, .header-catalog .parent > a:after {
  content: '';
  width: 12px;
  position: absolute;
  background: #9E9FA7;
  height: 1px;
  top: 6px;
  right: 6px;
  transition: 0.3s ease-in-out;
}

.header-catalog .parent > a:after {
  transform: rotate(90deg);
}

.header-catalog .parent > a.active:after {
  transform: rotate(0deg);
}

.header-catalog__mob-menu {
  display: none;
}

@media (max-width: 991px) {
  .header-catalog__mob-menu {
    display: block;
  }
}

.v1 {
  display: flex;
}

#top_menu ul li:not(:last-child) {
  margin-right: 32px;
}

#top_menu ul li a:hover, #top_menu ul li a#active {
  color: #8D65A7;
}

.burger {
  position: absolute;
  padding: 0;
  border: none;
  align-self: center;
  background: transparent;
  cursor: pointer;
  z-index: 9;
  flex-shrink: 0;
  width: 22px;
  height: 17px;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger.active span:nth-child(1) {
  transform: translate(0, 3px) rotate(45deg);
}

.burger.active span:nth-child(2) {
  width: 0;
}

.burger.active span:nth-child(3) {
  transform: translate(0, -13px) rotate(-45deg);
}

.burger span {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  transition: 0.3s ease-in-out;
  border-radius: 25px;
  background: #5C5C5C;
}

.burger span:nth-child(1) {
  top: 0;
  left: 0;
  bottom: 0;
}

.burger span:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:nth-child(3) {
  left: 0;
  bottom: 0;
}

.header-mobile-search {
  display: none;
  position: absolute;
  right: 62px;
  top: 24px;
}

@media (max-width: 991px) {
  .header-mobile-search {
    display: block;
  }
}

.header-catalog__mob-menu .js__trigger ul {
  display: none;
}

@media (max-width: 991px) {
  .container-fluid--catalog {
    display: none;
  }
}

.header-catalog__mob-menu ul li > a {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #5C5C5C;
}

.header-catalog__mob-menu > ul > li {
  margin-bottom: 20px;
}

.header-catalog__mob-menu > ul > li .js__masonry-menu {
  margin-left: 0;
  padding-top: 15px;
  margin-right: 0;
}

.simplebar-scrollbar:before {
  background: #C9C9C9;
  border-radius: 8px;
  left: 4px;
  right: 2px;
}

a.js__trigger-catalog svg {
  display: none;
  transition: 0.3s ease-in-out;
  vertical-align: 3px;
}

@media (max-width: 991px) {
  a.js__trigger-catalog svg {
    display: inline-block;
  }
}

a.js__trigger-catalog.active svg {
  display: inline-block;
  transform: rotate(180deg);
}

li .second {
  text-transform: capitalize;
}

.search-block__btn {
  border: none;
  background: transparent;
  position: relative;
  z-index: 9;
  padding-left: 0;
  padding-right: 0;
}

.search-block__input {
  font-size: 15px;
  line-height: 22px;
  right: 0;
  color: #909090;
  transition: 0.3s ease-in-out;
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #909090;
  padding-right: 29px;
  opacity: 0;
  padding-bottom: 8px;
  width: 0;
  background: #FFFFFF;
  z-index: 9;
}

.search-block__input:focus, .search-block__input:valid {
  opacity: 1;
  width: calc(100vw - 115px);
}

.footer {
  background: #6D6F7A;
  position: relative;
}

.footer-row {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-row {
    padding: 20px 0;
  }
}

.footer-menu ul {
  display: flex;
  padding: 0;
  list-style: none;
}

.footer-menu li:not(:last-child) {
  margin-right: 25px;
}

.footer-menu a {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.btn {
  height: 44px;
  padding: 0 33px;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  border: none;
  background: #8D65A7;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.btn:hover {
  color: #FFFFFF;
  background: #a585ba;
}

.btn--sm {
  height: 34px;
  padding: 0 24px;
}

.btn.disable:hover {
  background: #8D65A7;
  cursor: no-drop;
}

div#letkorz {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  background: #FFFFFF;
  width: 275px !important;
  margin: auto !important;
  box-shadow: 0px 0px 12px rgba(92, 92, 92, 0.24);
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
  display: none;
  padding: 20px;
  text-align: center;
}

.slide-out-div {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: none;
}

.slide-out-div.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-out-div__bg {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
  position: absolute;
}

.feed {
  max-width: 324px;
  width: 100%;
  z-index: 9999;
  position: relative;
  font: 12px/20px Verdana;
  background: #FFFFFF;
  color: #535252;
  box-shadow: 0px 0px 12px rgba(92, 92, 92, 0.24);
  padding: 24px 32px;
}

.feed__close {
  right: 12px;
  top: 12px;
  position: absolute;
  opacity: .8;
  cursor: pointer;
}

.feed__close:hover {
  opacity: 1;
}

.feed #ajax {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  display: block;
  color: #333333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}

#frm_back fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

#frm_back label {
  display: block;
  padding: 0px;
  margin: 5px 0px 5px 0px !important;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

#frm_back input {
  width: 100%;
  font: 12px/20px Verdana !important;
  color: #535252 !important;
  padding: 0px 5px 0px 5px;
  height: 40px;
  border: 1px solid #D6D6D6;
  margin-bottom: 16px;
}

#frm_back textarea {
  width: 100%;
  border: 1px solid #D6D6D6;
  padding: 0px 5px 0px 5px;
  font: 12px/20px Verdana !important;
  color: #535252 !important;
  margin-bottom: 24px;
  outline: none;
}

#frm_back span.req {
  color: red !important;
  display: none;
}

span.req {
  color: red !important;
}

#frm_back em {
  color: #9e82b1;
  font-size: 11px;
  padding: 0px;
  margin: 0px;
}

#frm_back #otvet {
  width: 140px;
  float: right;
  margin-right: 10px;
}

#frm_back .summa {
  display: block;
  float: left;
  margin-bottom: 12px;
}

#frm_back #name.error, #frm_back#numb.error, #frm_back #te.error, #frm_back#otvet.error, input.numb.error {
  border-color: red !important;
}

.w-100 {
  width: 100%;
  justify-content: center;
}

.form-btn {
  width: auto;
  height: auto;
  position: fixed;
  bottom: 60px;
  right: 20px;
  border: none;
  background: transparent;
}

@media (max-width: 767px) {
  .form-btn {
    position: absolute;
    right: 0;
    bottom: 100%;
  }
}

.form-btn path {
  transition: 0.3s ease-in-out;
}

.form-btn:hover path {
  fill: #8D65A7;
}

.btn-top path, .btn-top rect {
  transition: 0.3s ease-in-out;
}

.btn-top:hover path, .btn-top:hover rect {
  stroke: #000000;
}

:root {
  --swiper-theme-color:#ffff;
  --swiper-pagination-color: #ffff;
}

.main_part-slide {
  max-width: 1440px;
  margin: 0 auto 60px;
}

.slider-home-item {
  height: 540px;
  position: relative;
}

@media (max-width: 767px) {
  .slider-home-item {
    height: 400px;
  }
}

.slider-home-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-home-item__text {
  position: relative;
  z-index: 4;
  padding-top: 140px;
}

@media (max-width: 991px) {
  .slider-home-item__text {
    text-align: left;
    padding-top: 90px;
  }
}

.slider-home-item__title {
  max-width: 477px;
  font-size: 48px;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-bottom: 40px;
  color: #000000;
}

@media (max-width: 991px) {
  .slider-home-item__title {
    font-size: 28px;
    line-height: 36px;
    max-width: 700px;
    width: 200px;
    text-align: left;
    letter-spacing: 0.02em;
  }
}

@media (min-width: 992px) {
  .slider-home-item__title br {
    display: none;
  }
}

.slider-home-item__decr {
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 64px;
}

@media (max-width: 991px) {
  .slider-home-item__decr {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
    text-align: left;
    width: 154px;
    color: #000000;
  }
}

.home-slider .swiper-pagination {
  height: 14px;
}

.home-slider .home-slide-btn {
  width: auto;
  display: block;
}

.home-slider .home-slide-btn:after {
  display: none;
}

@media (max-width: 767px) {
  .home-slider .home-slide-btn {
    display: none;
  }
}

.home-slider .home-slide-btn rect {
  transition: 0.3s ease-in-out;
  fill-opacity: 0.1;
}

.home-slider .home-slide-btn:hover rect {
  fill-opacity: 0.6;
}

.home-slider .swiper-button-next {
  right: 0px;
}

.home-slider .swiper-button-prev {
  left: 0px;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet {
  background: #FFFFFF;
  opacity: 1;
}

.hot-slider-wrapper {
  position: relative;
  margin-bottom: 80px;
  text-align: center;
}

@media (max-width: 767px) {
  .hot-slider-wrapper {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .hot-slider-wrapper .swiper-container {
    padding-bottom: 58px;
  }
}

.hot-slider-wrapper .swiper-container .swiper-pagination-bullets-dynamic {
  height: 20px;
  display: none;
}

@media (max-width: 991px) {
  .hot-slider-wrapper .swiper-container .swiper-pagination-bullets-dynamic {
    display: block;
  }
}

.hot-slider-wrapper .swiper-button-custom {
  width: auto;
  height: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .hot-slider-wrapper .swiper-button-custom {
    display: none;
  }
}

.hot-slider-wrapper .swiper-button-custom:focus {
  outline: none;
}

.hot-slider-wrapper .swiper-button-custom:after {
  display: none;
}

.hot-slider-wrapper .swiper-button-prev, .hot-slider-wrapper .swiper-container-rtl .swiper-button-next {
  left: -60px;
}

@media (max-width: 767px) {
  .hot-slider-wrapper .swiper-button-prev, .hot-slider-wrapper .swiper-container-rtl .swiper-button-next {
    left: 9px;
  }
}

.hot-slider-wrapper .swiper-button-next, .hot-slider-wrapper .swiper-container-rtl .swiper-button-prev {
  right: -66px;
}

@media (max-width: 767px) {
  .hot-slider-wrapper .swiper-button-next, .hot-slider-wrapper .swiper-container-rtl .swiper-button-prev {
    right: 10px;
  }
}

.hot-slider-item {
  display: block;
}

.hot-slider-item:hover .hot-slider-item__overlay {
  opacity: 1;
}

.hot-slider-item__img {
  width: 100%;
  height: 400px;
  margin-bottom: 17px;
  background: #f9f9f9;
  position: relative;
}

@media (max-width: 767px) {
  .hot-slider-item__img {
    height: 240px;
  }
}

.hot-slider-item__img img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  display: block;
  overflow: hidden;
}

.hot-slider-item__text {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  margin-bottom: 8px;
  text-align: left;
  position: relative;
  z-index: 3;
}

.hot-slider-item__price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: left;
}

.hot-slider-item .hot-slider-item__overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #5C5C5C;
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 20px;
  align-items: center;
}

.wrapper_td {
  height: 320px;
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #cccccc;
  transition: 0.3s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wrapper_td:hover {
  border: 1px solid #a186be;
}

#table_order td {
  border: 1px solid #eeeeee;
  background: #f7f7f7;
  text-align: center !important;
}

.cmlt_1 {
  width: 200px;
  height: 153px;
  margin: 16px auto 0px auto;
}

a.details:hover {
  text-decoration: none !important;
}

.cmlt_3 {
  clear: both;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px 5px 10px;
  margin: 3px auto 0px auto !important;
  text-align: center;
  font: 13px/100% Verdana !important;
  color: #767676 !important;
  text-transform: none !important;
  border-bottom: 1px solid #c9c9c9;
  position: relative;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .cmlt_3 {
    border-bottom: 0 !important;
  }
}

span.spin {
  display: block;
  margin-left: 10px;
  float: left;
}

@media (max-width: 991px) {
  span.spin {
    display: none;
  }
}

.wrapp {
  border: 1px solid #e6e8d3;
  margin-bottom: 30px;
}

.wrapp__img {
  display: block;
  width: 100%;
  height: 205px;
}

.wrapp__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wrapp__text {
  display: block;
  width: 100%;
  padding: 7px 10px 7px 15px;
  background: #ececec;
  font: 11px/16px Verdana;
  text-transform: uppercase;
  text-decoration: none;
}

.wrapp__text a {
  color: #424242;
}

span.text_brands {
  display: none;
}

.wrapp.hover {
  border: 1px solid #9e82b2;
}

.wrapp p.bold.hover a {
  background: #9e82b2;
  color: #fff !important;
}

.firstnavi.parent > a {
  position: relative;
}

.firstnavi.parent > a:before, .firstnavi.parent > a:after {
  content: '';
  width: 12px;
  position: absolute;
  background: #9E9FA7;
  height: 1px;
  top: 16px;
  right: 12px;
  transition: 0.3s ease-in-out;
}

.firstnavi.parent > a:after {
  transform: rotate(90deg);
}

.firstnavi.parent > a.active:after {
  transform: rotate(0deg);
}

.banner {
  clear: both;
  width: 100%;
  padding-top: 17px;
}

@media (max-width: 991px) {
  .banner {
    display: none;
  }
}

.filename a {
  display: block;
  float: left;
  margin: 0 10px 10px 0px;
  padding: 5px;
  background: none !important;
  border: 2px solid #ececec;
  height: 132px;
  width: 132px;
}

.filename img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.catalog_menu--page {
  display: block;
}

@media (max-width: 767px) {
  .catalog_menu--page {
    display: none;
  }
}

.catalog_menu--page.open {
  display: block;
}

.catalog_menu--page-close {
  display: none;
}

@media (max-width: 767px) {
  .catalog_menu--page-close {
    display: block;
    padding: 15px 12px 15px 0;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .catalog_menu--page {
    position: fixed;
    background: #fff;
    width: 266px;
    left: 0;
    top: 0;
    z-index: 9999;
    height: 100%;
    overflow-x: auto;
  }
}

@media (max-width: 767px) {
  #content .txt-for h3 {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #333333;
  }
}

@media (max-width: 767px) {
  .goods_photo {
    display: none;
  }
}

@media (max-width: 767px) {
  .filename {
    display: none;
  }
}

@media (max-width: 767px) {
  .omlt, .descr, .crit, table#characteristics {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #characteristics td {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #6D6F7A;
  }
}

.product-slider {
  display: none;
}

@media (max-width: 767px) {
  .product-slider {
    display: block;
  }
}

.product-slider img {
  width: 100%;
  height: 261.34px;
  object-fit: contain;
}

.omlt_1_1 {
  text-align: left;
  margin: 10px 0px 0px 0px;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #333333;
}

.omlt_1_1 b {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.goods_description {
  margin: 18px 0px 0px 0px;
  width: 100%;
}

.omlt {
  max-width: 530px;
  float: right;
  margin: 30px 0px 0px 0px;
}

h1.h1_detail_description {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
}

.descr {
  max-width: 530px;
  width: 100%;
  float: right;
}

.crit {
  float: left;
  padding: 0px !important;
  margin: 0px;
  text-align: left;
}

.detail_wrap_spinner {
  display: flex;
  align-items: center;
}

.detail_wrap_spinner span {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

@media (max-width: 767px) {
  .detail_wrap_spinner span {
    font-size: 12px;
    line-height: 18px;
  }
}

.omlt_2 {
  margin: 18px 0px 30px 0px;
  text-align: left;
}

@media (max-width: 767px) {
  .omlt_2 {
    margin: 18px 0px 24px 0px;
    font-size: 14px;
    line-height: 20px;
  }
}

.prise-row {
  display: flex;
  justify-content: space-between;
  max-width: 375px;
  margin-bottom: 35px;
}

.prise-row .with_spinner input, .prise-row .with_spinner2 input {
  width: 30px !important;
}

.button_buy.detail_description {
  float: left;
  height: 44px;
  width: 100%;
  max-width: 200px;
  padding: 0 10px;
  background: #8D65A7;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
}

.button_buy.detail_description:hover {
  color: #FFFFFF;
  background: #a585ba;
}

.crit {
  width: 100%;
}

.crit table {
  width: 100%;
}

button.close2 {
  right: 7px !important;
  left: auto !important;
  top: 5px !important;
  border: 0;
  position: absolute;
  background: transparent;
}

.desc_caption {
  color: #333333;
}

.ch {
  font-size: 11px;
  margin: 0 3px;
}

@media (max-width: 767px) {
  .ch {
    font-size: 10px;
  }
}

.breadcramps {
  margin-bottom: 34px;
}

.breadcramps .last {
  color: #000000;
}

.product-slider .swiper-pagination-bullet {
  background: #C9C9C9;
}

@media (max-width: 767px) {
  div#first tr {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .button-bay {
    display: flex;
    width: 100% !important;
    height: 44px !important;
    margin: 0 !important;
    justify-content: center;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 2px;
    text-transform: uppercase !important;
  }
}

@media (max-width: 767px) {
  .pc-row {
    display: none;
  }
}

@media (max-width: 767px) {
  .row-mob-flex {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid;
    padding: 16px 12px 0;
    background: #F8F8F9;
  }
}

@media (max-width: 767px) {
  .row-mob-flex .td-grid {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
}

@media (max-width: 767px) {
  .row-mob-flex .row-cine {
    display: flex;
    width: 100% !important;
    float: right;
  }
}

@media (max-width: 767px) {
  #content table, #content tbody {
    width: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
  #table_order td {
    border: 1px solid #eeeeee;
    background: #f7f7f7;
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  #table_order td .img {
    border: 1px solid #D6D6D6;
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 767px) {
  #table_order td span, #table_order td a {
    text-align: left;
  }
}

@media (max-width: 767px) {
  #table_order td {
    border: 0;
    padding: 0;
    margin-bottom: 16px;
  }
}

.mob-title {
  display: none;
}

@media (max-width: 767px) {
  .mob-title {
    display: block;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #6D6F7A;
  }
}

@media (max-width: 767px) {
  .mob-title + span, .mob-title + a {
    font-size: 12px;
    line-height: 18px;
    color: #333333;
  }
}

@media (max-width: 767px) {
  .ddel {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

@media (max-width: 767px) {
  .countch {
    width: 38px;
    height: 26px;
  }
}

@media (max-width: 767px) {
  .bottom-td {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 767px) {
  .bottom-bay {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 26px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .bottom-bay td {
    background: transparent !important;
  }
}

@media (max-width: 767px) {
  .bottom-bay__left {
    font-size: 16px !important;
    color: #000000 !important;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 767px) {
  .bottom-bay__right {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 767px) {
  .bottom-bay__right #clearbask {
    font-size: 12px;
    text-decoration: underline !important;
    color: #8d65a7 !important;
  }
}

@media (max-width: 767px) {
  .for_oform {
    display: none;
  }
}

#first {
  font-size: 12px;
  line-height: 18px;
  color: #6D6F7A;
}

#first h2 {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .sort {
    display: none;
  }
}

.bay_catalog {
  display: none;
  border: none;
  background: transparent;
  font-size: 14px;
  line-height: 24px;
  color: #6D6F7A;
}

@media (max-width: 767px) {
  .bay_catalog {
    display: block;
    margin-bottom: 24px;
  }
}

a.second:not([href]) {
  position: relative;
}

a.second:not([href]):before, a.second:not([href]):after {
  content: '';
  width: 12px;
  position: absolute;
  background: #9E9FA7;
  height: 1px;
  top: 16px;
  right: 12px;
  transition: 0.3s ease-in-out;
}

a.second:not([href]):after {
  transform: rotate(90deg);
}

a.second:not([href]).active:after {
  transform: rotate(0deg);
}
