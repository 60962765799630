
.slide-out-div{
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: none;
  &.open{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__bg{
    background: rgba(0, 0, 0, .7);
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    position: absolute;
  }
}
.feed {
  max-width: 324px;
  width: 100%;
  z-index: 9999;
  position: relative;
  font: 12px/20px Verdana;
  background: #FFFFFF;
  color: #535252;
  box-shadow: 0px 0px 12px rgba(92, 92, 92, 0.24);
  padding: 24px 32px; 
  &__close{
    right: 12px;
    top: 12px;
    position: absolute;
    opacity: .8;
    cursor: pointer;
    &:hover{
      opacity: 1;
    }
  }
}

.feed #ajax {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  display: block;
  color: #333333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;

}

#frm_back fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

#frm_back label {
  display: block;
  padding: 0px;
  margin: 5px 0px 5px 0px !important;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

#frm_back input {
  width: 100%;
  font: 12px/20px Verdana !important;
  color: #535252 !important;
  padding: 0px 5px 0px 5px;
  height: 40px;
  border: 1px solid #D6D6D6;
  margin-bottom: 16px;
}

#frm_back textarea {
  width: 100%;
  border: 1px solid #D6D6D6;
  padding: 0px 5px 0px 5px;
  font: 12px/20px Verdana !important;
  color: #535252 !important;
  margin-bottom: 24px;
  outline: none;
}

#frm_back span.req {
  color: red !important;
  display: none;
}

span.req {
  color: red !important;
}

#frm_back em {
  color: #9e82b1;
  font-size: 11px;
  padding: 0px;
  margin: 0px;
}

#frm_back.sub {
}

#frm_back #otvet {
  width: 140px;
  float: right;
  margin-right: 10px;
}

#frm_back .summa {
  display: block;
  float: left;
  margin-bottom: 12px;
}

#frm_back #name.error, #frm_back#numb.error, #frm_back #te.error, #frm_back#otvet.error, input.numb.error{
  border-color: red !important;
}

.w-100{
  width: 100%;
 justify-content: center;
}
.form-btn{
  width: auto;
  height: auto;
  position: fixed;
  bottom: 60px;
  right: 20px;
  border: none;
  background: transparent;
 @include to(md){
   position: absolute;
   right: 0;
   bottom: 100%;
 }
  path {
    transition: $trans;
  }
  &:hover{
    path {
      fill: #8D65A7;
    }
  }

}
.btn-top{

  path, rect {
    transition: $trans;
  }
  &:hover{
    path, rect {
      stroke:#000000;
    }

  }
}
