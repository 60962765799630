:root {
  --swiper-theme-color:#ffff;
  --swiper-pagination-color: #ffff;
}
.main_part-slide{
  max-width: 1440px;
  margin: 0 auto 60px;
}
.slider-home-item{
  height: 540px;
  position: relative;
  @include to(md){
    height: 400px
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__text{
    position: relative;
    z-index: 4;
    padding-top: 140px;
    @include to(lg){
      text-align: left;
      padding-top: 90px;
    }
  }
  &__title{
    max-width: 477px;
    font-size: 48px;
    line-height: 60px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    margin-bottom: 40px;
    color: #000000;
    @include to(lg){
      font-size: 28px;
      line-height: 36px;
      max-width: 700px;
      width: 200px;
      text-align: left;
      letter-spacing: 0.02em;
    }
    br{
      @include from(lg){
        display: none;
      }
    }
  }
  &__decr{
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 64px;
    @include to(lg){
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      text-align: left;
      width: 154px;
      color: #000000;
    }
  }

}
.home-slider{
  .swiper-pagination {
    height: 14px;

  }
  .home-slide-btn{
    width: auto;
    &:after{
      display: none;
    }
    display: block;
    @include to(md){
      display: none;
    }
    rect{
      transition: $trans;
      fill-opacity: 0.1;
    }
    &:hover{
      rect{
        fill-opacity: 0.6;
      }
    }
  }
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev {
    left: 0px;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1;
  width: 10px;
  height: 10px;
}
.swiper-pagination-bullet {
  background: #FFFFFF;
  opacity: 1;
}

/// hot slider
.hot-slider-wrapper{
  position: relative;
  margin-bottom: 80px;
  text-align: center;
  @include to(md){
    margin-bottom: 40px;
  }
  .swiper-container {
    @include to(md){
      padding-bottom: 58px;
    }
    .swiper-pagination-bullets-dynamic {
      height: 20px;
      display: none;
      @include to(lg){
        display: block;
      }
    }
  }
  .swiper-button-custom{
    width: auto;
    height: auto;
    transform: translateY(-50%);
    @include to(lg){
      display: none;
    }
    &:focus{
      outline: none;
    }
    &:after{
      display: none;
    }
  }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: -60px;
    @include  to(md){
      left: 9px;
    }
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: -66px;
    @include  to(md){
      right: 10px;
    }
  }
}
.hot-slider-item{
  display: block;
  &:hover{
    .hot-slider-item__overlay {
      opacity: 1;
    }
  }
  &__img{
    width: 100%;
    height: 400px;
    margin-bottom: 17px;
    background: #f9f9f9;
    position: relative;
    @include to(md){
      height: 240px;
    }
    img{
      width: 100%;
      object-fit: contain;
      height: 100%;
      display: block;
      overflow: hidden;
    }
  }
  &__text{
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    margin-bottom: 8px;
    text-align: left;
    position: relative;
    z-index: 3;
  }
  &__price{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-align: left;
  }
  .hot-slider-item__overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.64);
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: $trans;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #5C5C5C;
  }
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 20px;
  align-items: center;
}