.header{
  position: sticky;
  top: 0em;
  background: #fff;
  z-index: 999;
  &-top{
    display: flex;
    background: #FFFFFF;
    &__row{
      display: flex;
      padding: 14px 0;
      align-items: center;
    }
    &__local{
      @include to(lg){
        display: none;
      }
    }
  }
  &-bottom {
    background: #F8F8F9;
    position: relative;
    @include to(lg){
      position: static;
    }

   &__row{
     align-items: center;
     display: flex;
     height: 56px;
     @include to(lg){
       height: 0;
     }

   }
   &__local{
     display: none;
     @include to(lg){
       display: block;
     }
   }
  }
}
#ofbtn{
  @include to(lg){
    display: none;
  }
}
.logo {
  width: 206px;
  margin-right: auto;
  height: 52px;
  display: block;
  @include to(lg){
    width: 126.77px;
    margin: 0 auto;
  }
  &.logo--ru{
    background: url(../images/general/logo-ru.svg) no-repeat center;
    background-size: contain;
  }
  &.logo--ua{
    background: url(../images/general/logo-ua.svg) no-repeat center;
    background-size: contain;
  }
  &.logo--en{
    background: url(../images/general/logo-en.svg) no-repeat center;
    background-size: contain;
  }
}
.search2{
  border-radius: 20px;
  overflow: hidden;
  height: 40px;
  position: relative;
  background: #F8F8F9;
  @include to(lg){
    display: none;
  }
  input{
    position: absolute;
    width: 100%;
    border: 0;
    font-size: 12px;
    line-height: 22px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #909090;
    left: 0;
    background: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    padding-left: 50px;

  }
  #sf_button{
    position: absolute;
    top: 7px;
    left: 13px;
  }
}
.search{
  width: 100%;
  max-width:433px;
  height:33px;
  margin:0px 0px 0px 0px;
  @include to(lg){
    display: none;
  }
}

.search .input{
  margin:0px;
  position:relative;}


.lg{
  display: flex;
  padding-left: 0;
  margin-left: 30px;
  @include to(lg){
    margin-left: 0;
    margin-bottom: 38px;
  }
  a{
    font-size: 12px;
    line-height: 18px;
    color: #6D6F7A;
    &:hover{
      color: #8D65A7;
    }
  }
}
body.ru .lg .ru a{
  color: #8D65A7 !important;
}
body.ua .lg .uk a{
  color: #8D65A7;
}
body.en .lg .eng a{
  color: #8D65A7;
}
.razd{
  margin: 0 4px;
  font-size: 12px;
  line-height: 27px;
  color: #6D6F7A;

}
#korzinfo{
  display: flex;
  @include to(lg){
    position: absolute;
    right: 0;
    top: 28px;
  }

}
#top_menu {
  margin-right: auto;
  @include to(lg){
    display: none;
  }
}

.bay-block{
  display: flex;
  align-items: center;
  margin-right: 18px;
  &__text{
    font-size: 12px;
    line-height: 18px;
    color: #333333;
    @include to(lg){
      display: none;
    }
  }
}
.bay-icon{
  position: relative;
  margin-right: 12px;
  display: block;
  &__cine{
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #8D65A7;
    color: #FFFFFF;
    position: absolute;
    border-radius: 50%;
    padding: 1px 5px;
    right: -5px;
    top: -5px;
  }
}

.header-catalog{
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 9;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(92, 92, 92, 0.24);
  padding-top: 30px;
  padding-bottom: 15px;
  max-height: 520px;
  overflow-x: auto;
  opacity: 0;
  pointer-events: none;
  transition: $trans;
  @include to(lg){
    position: fixed;
    background: #FFFFFF;
    max-height: 100%;
    padding-top: 16px;
    padding-left: 14px;
    padding-right: 14px;
    top: 0;
    height: 100%;
    max-width: 266px;
    left: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  }
  &.open{
      opacity: 1;
      pointer-events: auto;
  }
  &__close{
    display: none;
    right: 15px;
    top: 15px;
    position: absolute;
    @include to(lg){
      display: block;
    }
  }

  .firstnavi{
    margin-bottom: 16px;
    ul{
      margin-top: 5px;
    }
  }
  .v1{

    & > li{
      margin-bottom: 16px;
    }
  }
  ul{
    list-style: none;
    padding: 0;
  }
  .firstnavi > a{
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    text-transform: uppercase;
  }
  .second{
    font-size: 12px;
    line-height: 18px;
    color: #5C5C5C;
    @include to(lg){
      text-transform: none;
    }
  }
  a{
    transition: $trans;
    &:hover{
      color: $accent;
      &:before, &:after{
        background: $accent !important;
      }
    }
  }
  .parent{

    &> a{
      position: relative;
      display: block;
      padding-right: 26px;

      &:before,&:after{
        content: '';
        width: 12px;
        position: absolute;
        background: #9E9FA7;
        height: 1px;
        top: 6px;
        right: 6px;
        transition: $trans;
      }
      &:after{
        transform: rotate(90deg);
      }
      &.active{
        &:after{
          transform: rotate(0deg);
        }
      }
    }
  }
  &__mob-menu{
    display: none;
    @include to(lg){
      display: block;
    }
  }
}

.v1{
  display: flex;
}

#top_menu  ul li:not(:last-child) {
  margin-right: 32px;
}
#top_menu ul li a:hover ,#top_menu ul li a#active{
  color: #8D65A7;
}

.burger {
  position: absolute;
  padding: 0;
  border: none;
  align-self: center;
  background: transparent;
  cursor: pointer;
  z-index: 9;
  flex-shrink: 0;
  width: 22px;
  height: 17px;
  @include from(lg) {
    display: none;
  }

  &.active {
    span:nth-child(1) {
      transform: translate(0, 3px) rotate(45deg);
    }

    span:nth-child(2) {
      width: 0;
    }

    span:nth-child(3) {
      transform: translate(0, -13px) rotate(-45deg);
    }
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: $trans;
    border-radius: 25px;
    background: #5C5C5C;

    &:nth-child(1) {
      top: 0;
      left: 0;
      bottom: 0;
    }

    &:nth-child(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      left: 0;
      bottom: 0;
    }
  }
}
.header-mobile-search{
  display: none;
  position: absolute;
  right: 62px;
  top: 24px;
  @include to(lg){
    display: block;
  }
}
.header-catalog__mob-menu  .js__trigger ul{
  display: none;
}
.container-fluid--catalog{
  @include to(lg){
    display: none;
  }
}
.header-catalog__mob-menu ul li > a{
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #5C5C5C;
}
.header-catalog__mob-menu > ul > li{
  margin-bottom: 20px;

  .js__masonry-menu{
    margin-left: 0;
    padding-top: 15px;
    margin-right: 0;
  }
}
.simplebar-scrollbar:before {
  background: #C9C9C9;
  border-radius: 8px;
  left: 4px;
  right: 2px;
}
a.js__trigger-catalog{
  svg{
    display: none;
    transition: $trans;
    vertical-align: 3px;
    @include to(lg){
      display: inline-block;
    }
  }
  &.active svg{
    display: inline-block;
    transform: rotate(180deg);
  }
}
li .second{
  text-transform: capitalize;
}
.search-block {
  &__btn {
    border: none;
    background: transparent;
    position: relative;
    z-index: 9;
    padding-left: 0;
    padding-right: 0;
  }

  &__input {
    font-size: 15px;
    line-height: 22px;
    right: 0;
    color: #909090;
    transition: $trans;
    position: absolute;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #909090;
    padding-right: 29px;
    opacity: 0;
    padding-bottom: 8px;
    width: 0;
    background: #FFFFFF;
    z-index: 9;
    &:focus, &:valid {
      opacity: 1;
      width: calc(100vw - 115px);
    }
  }
}

