.btn{
  height: 44px;
  padding: 0 33px;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  transition: $trans;
  border: none;
  background: #8D65A7;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  &:hover{
    color: #FFFFFF;
    background: lighten(#8D65A7 , 10%);
  }
  &--sm{
    height: 34px;
    padding: 0 24px;
  }
}
.btn.disable:hover{
  background: $accent;
  cursor: no-drop;
}

div#letkorz {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  background: #FFFFFF;
  width: 275px !important;
  margin: auto !important;
  box-shadow: 0px 0px 12px rgba(92, 92, 92, 0.24);
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
  display: none;
  padding: 20px;
  text-align: center;
}